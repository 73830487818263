import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { PATH } from "../routes/constants";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import OurStory from "../images/our-story.png";
import OurLogo from "../images/our-logo.png";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import { Link as ScrollLink } from "react-scroll";
import "../scss/global.scss";

function AboutUs() {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const slideCount = 3;
  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href={PATH.HOME}>Plans</Nav.Link>
                  <Nav.Link href={PATH.HOME}>Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    About Us <small></small>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
            <div className="gradiant-bg px-5 py-5">
              <Row className="align-items-center">
                <Col md={12}>
                  <p>
                    Cuspyd is an exclusive edutech platform for dental students
                    created by dentists, powered by technology to change the way
                    in which dental professionals learn and grow. Our mission is
                    to provide students with a comprehensive, innovative, and
                    engaging learning experience that equips them with the
                    knowledge, skills, and confidence they need to succeed in
                    the rapidly evolving field of dentistry.
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <h5 className="mb-4 mt-2">Our Story</h5>
                <Col md={6}>
                  <img className="our-story-img" src={OurStory} alt="Cuspyd" />
                </Col>
                <Col md={6}>
                  <p>
                    The idea for Cuspyd was initiated with the belief that
                    dentistry is fun and it gets even better when it is more
                    accessible. We at Cuspyd strongly believe that the brain
                    recognizes what the eye sees so we show you what exactly
                    will help you navigate dental school. Using 3D animations,
                    take a closer look at what you can't visualize in your
                    classes, supplement theoretical learning with our carefully
                    curated questions to enhance your results and browse through
                    our structured notes with easy to draw diagrams to ace your
                    exams. It's like having a dental school right at your
                    fingertips. With us, you can study on your own schedule and
                    at your own pace, from anywhere.
                  </p>
                  <p>
                    The name cuspyd was inspired by the canine tooth. Cuspids
                    are teeth located strategically as we move from the front to
                    the back of the mouth. Similarly, our vision is to help a
                    budding dentist transition smoothly from school to BDS and
                    from BDS to MDS- bridging the gap between classrooms to
                    clinics. A canine tooth is also known as the cornerstone of
                    the mouth and we wish to serve as an anchor in the field of
                    dentistry.
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <h5 className="mb-4 mt-2 text-center">Our Logo</h5>
                <Col className="text-center">
                  <img className="our-logo" src={OurLogo} alt="Cuspyd" />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
}

export default AboutUs;
