import { BrowserRouter as Router, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { PATH } from "./routes/constants";
import RouteList from "./routes/routeList";
import AOS from "aos";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import AboutCuspyd from "./images/abt-Logo.png";
import MainLogo from "./images/logo.png";
import { Link as ScrollLink } from "react-scroll";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";


import "aos/dist/aos.css";

AOS.init({
  duration: 2000,
});

const App = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  const [show2, setShow2] = useState(false);
  const JoinUs = () => setShow2(true);
  const JoinUsClose = () => setShow2(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Router>
      <div>
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link as={ScrollLink} to="section1" smooth={true} duration={500}>Plans</Nav.Link>
                  <Nav.Link as={ScrollLink} to="contactus" smooth={true} duration={500}>Contact Us</Nav.Link>
                  <Nav.Link onClick={JoinUs}
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Modal
                    className="custom-model getin-touch"
                    show={show2}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={JoinUsClose}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Create an Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form>
              <Row>
                <Col>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Enter Full Name"
                    name="name"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Email Address"
                    name="name"
                  />
                </Col>
                <Col>
                  <Row>
                    <Col xs="4">
                      <Form.Select
                        size="lg"
                        aria-label="Country Code"
                        name="countryCode"
                      >
                        <option>+91</option>
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Enter Phone Number"
                        name="phone"
                      />
                    </Col>
                  </Row>
                </Col>
                <Row>
                <Col>
                  
                </Col>
              </Row>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Row>
                    <Col>
                    <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Password"
                        name="phone"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Select
                    size="lg"
                    aria-label="Select Your Course"
                    name="course"
                  >
                    <option>Select Your Course</option>
                    <option value="1">I B.D.S</option>
                    <option value="2">II B.D.S</option>
                    <option value="3">III B.D.S</option>
                    <option value="4">IV B.D.S</option>
                    <option value="5">Intern</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Row>
                    <Col>
                    <Form.Select
                    size="lg"
                    aria-label="Select Your Course"
                    name="course"
                  >
                    <option>Select Your State/Country</option>
                  </Form.Select>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Select
                    size="lg"
                    aria-label="Select Your Course"
                    name="course"
                  >
                    <option>Select Your College</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Row>
                    <Col>
                    <Form.Select
                    size="lg"
                    aria-label="Select Your Course"
                    name="course"
                  >
                    <option>Select Your Education</option>
                  </Form.Select>
                    </Col>
                  </Row>
                </Col>
                <Col>
                <Form.Control
                        size="lg"
                        type="text"
                        placeholder="reffaal code"
                        name="phone"
                      />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                  label={"I accept Cuspyd - Terms & Conditions"}
                />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="text-center">
                  <Button
                    className="btn-primary-1"
                    size="lg"
                    variant="primary"
                    type="submit"
                  >
                    SIGNUP
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-4 pb-4">
                  <span>Already have an account? <a href="#">Login</a> </span>
                </Col>
              </Row>
            </Form>
                    </Modal.Body>
                    {/* <Modal.Footer>
                      <Button variant="secondary" onClick={JoinUs}>
                        Close
                      </Button>
                    </Modal.Footer> */}
                  </Modal>
            </Container>
          </Navbar>
        </div>
        <RouteList />
      </div>
    </Router>
  );
};

export default App;
