import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MainLogo from "../images/logo.png";
import { PATH } from "../routes/constants";
import AboutCuspyd from "../images/abt-Logo.png";
import AboutUsBg from "../images/about.png";
import Accordion from "react-bootstrap/Accordion";
import AOS from "aos";
import "aos/dist/aos.css";
import "leaflet/dist/leaflet.css";
import "../scss/landing.scss";
import "../scss/inner.scss";
import "../scss/global.scss";
import { Link as ScrollLink } from "react-scroll";

function PrivacyPolicy() {
  const [index, setIndex] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const position = [51.505, -0.09];

  const slideCount = 3;

  const generateRandomIndex = () => {
    return Math.floor(Math.random() * slideCount);
  };

  useEffect(() => {
    const randomIndex = generateRandomIndex();
    setIndex(randomIndex);
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <div className="landing-page">
        <div className="custom-header">
          <Navbar
            expand="lg"
            bg={isScrolled ? "dark" : "transparent"}
            className={`fixed-top ${isScrolled ? "scrolled" : ""}`}
          >
            <Container>
              <Navbar.Brand href={PATH.HOME}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <img
                  className="dark-logo"
                  height={30}
                  src={AboutCuspyd}
                  alt="Cuspyd"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                <Nav>
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link as={ScrollLink} to="section1" smooth={true} duration={500}>Plans</Nav.Link>
                  <Nav.Link as={ScrollLink} to="section2" smooth={true} duration={500}>Contact Us</Nav.Link>
                  <Nav.Link
                    target="_new"
                    href="https://play.google.com/store/apps/details?id=com.cuspydApp.cuspyd"
                  >
                    Join Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="inner-page">
          <img className="inner-page__banner" src={AboutUsBg} alt="Cuspyd" />
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <strong>
                    Privacy Policy <small></small>
                  </strong>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="inner-body">
          <Container>
            <div className="gradiant-bg px-5 py-5">
              <Row className="align-items-center">
                <Col>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="acr_01">
                        Introduction
                      </Accordion.Header>
                      <Accordion.Body>
                        This Privacy Policy governs the manner in which Cuspyd
                        collects, uses, maintains and discloses information
                        collected from users (each, a "User") of the
                        www.cuspyd.com(referred to as the "Website"). This
                        privacy policy applies to the Website, App, and all
                        products and services offered by Cuspyd.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Personal Identification Information
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          We may collect personal identification information
                          from Users in a variety of ways, including, but not
                          limited to, when Users visit our website/app, register
                          on the website/app, and in connection with other
                          activities, services, features or resources, that we
                          make available on our website.
                        </p>
                        <p>
                          Users may be asked for, as appropriate, name,
                          username, password, email address, mailing address,
                          phone number, credit card information, the specialty
                          in which you practice or intend to practice (selected
                          from a drop-down menu if applicable), year of
                          graduation or year of joining undergraduate medical
                          program and the name of your college or university.
                        </p>
                        <p>
                          Users may choose to visit our website anonymously.
                        </p>
                        <p>
                          We collect personal identification information from
                          Users only if they voluntarily submit such information
                          to us.
                        </p>
                        <p>
                          Users may refuse to provide their personal
                          identification information; however, it may prevent
                          them from engaging in certain website-related
                          activities.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Non-personal Identification Information
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          We may collect personal identification information
                          from Users in a variety of ways, including, but not
                          limited to, when Users visit our website/app, register
                          on the website/app, and in connection with other
                          activities, services, features or resources, that we
                          make available on our website.
                        </p>
                        <p>
                          Users may be asked for, as appropriate, name,
                          username, password, email address, mailing address,
                          phone number, credit card information, the specialty
                          in which you practice or intend to practice (selected
                          from a drop-down menu if applicable), year of
                          graduation or year of joining undergraduate medical
                          program and the name of your college or university.
                        </p>
                        <p>
                          Users may choose to visit our website anonymously.
                        </p>
                        <p>
                          We collect personal identification information from
                          Users only if they voluntarily submit such information
                          to us.
                        </p>
                        <p>
                          Users may refuse to provide their personal
                          identification information; however, it may prevent
                          them from engaging in certain website-related
                          activities.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Web Browser Cookies</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          We may collect personal identification information
                          from Users in a variety of ways, including, but not
                          limited to, when Users visit our website/app, register
                          on the website/app, and in connection with other
                          activities, services, features or resources, that we
                          make available on our website.
                        </p>
                        <p>
                          Users may be asked for, as appropriate, name,
                          username, password, email address, mailing address,
                          phone number, credit card information, the specialty
                          in which you practice or intend to practice (selected
                          from a drop-down menu if applicable), year of
                          graduation or year of joining undergraduate medical
                          program and the name of your college or university.
                        </p>
                        <p>
                          Users may choose to visit our website anonymously.
                        </p>
                        <p>
                          We collect personal identification information from
                          Users only if they voluntarily submit such information
                          to us.
                        </p>
                        <p>
                          Users may refuse to provide their personal
                          identification information; however, it may prevent
                          them from engaging in certain website-related
                          activities.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        How We Use Collected Information
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          We may collect personal identification information
                          from Users in a variety of ways, including, but not
                          limited to, when Users visit our website/app, register
                          on the website/app, and in connection with other
                          activities, services, features or resources, that we
                          make available on our website.
                        </p>
                        <p>
                          Users may be asked for, as appropriate, name,
                          username, password, email address, mailing address,
                          phone number, credit card information, the specialty
                          in which you practice or intend to practice (selected
                          from a drop-down menu if applicable), year of
                          graduation or year of joining undergraduate medical
                          program and the name of your college or university.
                        </p>
                        <p>
                          Users may choose to visit our website anonymously.
                        </p>
                        <p>
                          We collect personal identification information from
                          Users only if they voluntarily submit such information
                          to us.
                        </p>
                        <p>
                          Users may refuse to provide their personal
                          identification information; however, it may prevent
                          them from engaging in certain website-related
                          activities.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        How We Protect Your Information
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our website.</li>
                          <li>We do not sell, trade, or rent Users personal identification information to others.</li>
                          <li>We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</li>
                          <li>We will retain User identification information (personal and non-personal) as described above for as long as you use the website, products, app or services and will delete such information if you delete your account.</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                      Changes To This Privacy Policy
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>This Privacy Policy may be updated from time to time for any reason.</li>
                          <li>We encourage Users to frequently check this page for any changes to stay informed about how we protect the personal information we collect.</li>
                          <li>We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here</li>
                          <li>You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</li>
                          <li>Unless stated otherwise, the most current version of the Privacy Policy applies to all information that we have about you/User.</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                      Disclaimer
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>Please be aware that, although DocTutorials will take reasonable steps to safeguard and maintain security of User Identification Information (personal and non-personal), that we process and maintain, no security system can prevent all potential security breaches.</li>
                          <li>Please refer to the Terms and Conditions of Service for more details about DocTutorials and User obligations with respect to the proper use of the Website, App, products and Services.</li>
                          <li>DocTutorials is not responsible or liable for any information disclosed by you (User) or collected by a third party in an external link.</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                      Disclaimer
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>By using this website, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our website. Your continued use of our website, following the posting of the changes to this policy, will be deemed as your acceptance of those changes.</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                      <Accordion.Header>
                      Contact Us
                      </Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>If you have any questions about this Privacy Policy, the practices of this website, or your dealings with this site, please write to us at: <a href="mailto:help@doctutorials.com">help@doctutorials.com</a></li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <footer className="text-white custom-fotter">
          <Container>
            <Row className="py-4">
              <Col md={4}>
                <Nav className="list-unstyled">
                  <Nav.Link href={PATH.ABOUT}>About Us</Nav.Link>
                  <Nav.Link href={PATH.BLOG}>Blogs</Nav.Link>
                  <Nav.Link href="#plans">Plans</Nav.Link>
                  <Nav.Link href={PATH.FAQS}>FAQs</Nav.Link>
                  <Nav.Link href={PATH.TermsandConditions}>Terms & Conditions</Nav.Link>
                  <Nav.Link href="#contact">Contact Us</Nav.Link>
                  <Nav.Link href={PATH.PrivacyPolicy}>Privacy Policy</Nav.Link>
                </Nav>
              </Col>
              <Col md={4}>
                <ul className="list-unstyled">
                  <li>
                    <a target="_new" href="https://www.facebook.com/cuspyd">
                      <i class="bi bi-facebook"></i> Facebook
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.instagram.com/cuspyd/">
                      <i class="bi bi-instagram"></i> Instagram
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://www.youtube.com/@cuspyd">
                      <i class="bi bi-youtube"></i> Youtube
                    </a>
                  </li>
                  <li>
                    <a target="_new" href="https://twitter.com/cuspyd_dental">
                      <i class="bi bi-twitter-x"></i> X
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <img
                  className="light-logo"
                  height={30}
                  src={MainLogo}
                  alt="Cuspyd"
                />
                <ul className="list-unstyled mt-4">
                  <li>
                    <i class="bi bi-telephone-fill"></i> 8877534567
                    (10 AM TO 7 PM)
                  </li>
                  <li>
                    <i class="bi bi-envelope"></i> help@cuspyd.com
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="text-center">
              <Col>
                <p>&copy; 2024 Cuspyd. All rights reserved.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
